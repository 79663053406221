import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import MapList from '../views/plantMapList.vue'
import JukiList from '../views/plantJukiList.vue'
import KadoList from '../views/plantKadoList.vue'
import TableTest from '../views/tabletest.vue'
import SiryoAndLink from '../views/SiryoAndLink.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    name: 'Test',
    component: TableTest,

  },
  {
    path: '/',
    name: 'Top',
    component: MapList,
    meta:{title:'全国建設発生土リサイクル協会'}
  },
  {
    path: '/jukilist',
    name: 'JukiList',
    component: JukiList,
    meta:{title:'全国建設発生土リサイクル協会'}
  },
  {
    path: '/kadolist',
    name: 'KadoList',
    component: KadoList,
    meta:{title:'全国建設発生土リサイクル協会'}
  },
  {
    path: '/siryo',
    name: 'Siryo',
    component: SiryoAndLink,
    meta:{title:'全国建設発生土リサイクル協会'}
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
