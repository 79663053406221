<template>
  <div class="d-flex justify-center">
    <div>
      <h1 class="text-center my-5">プラント保有重機情報</h1>
      <div class="d-flex justify-end">
        <div class="d-flex d-inline-flex align-center my-3">
          <span>絞り込み条件</span>
          <v-select
              class="mx-2"
              label="支部"
              v-model="selShibu"
              :items="shibu"
              item-text="shibu_name"
              item-value="shibu_cd"
              outlined
              dense
              hide-details=""
              persistent-hint
              style="width:200px;"
              @change="changeShibu"
              return-object
          ></v-select>
          <v-select
              label="都道府県"
              v-model="selTdfk"
              :items="dispTdfk"
              item-text="tdfk_name"
              item-value="tdfk_cd"
              outlined
              dense
              hide-details=""
              persistent-hint
              style="width:200px;"
              @change="changeTdfk"
              return-object
          ></v-select>
        </div>
      </div>

      <v-card>
      <v-simple-table
        fixed-header
        height="500px"
        class="planttable"
      >
        <template v-slot:default>
          <col style="width: 200px" />
          <col style="width: 200px" />
          <col style="width: 300px" />
          <col style="width: 120px" />
          <col style="width: 150px" />
          <col style="width: 100px" />
          <col style="width: 100px" />
          <col style="width: 300px" />
          <col style="width: 100px" />

          <thead>
            <tr>
              <th class="grey lighten-3">
                会員名
              </th>
              <th class="grey lighten-3">
                プラント名称
              </th>
              <th class="grey lighten-3">
                所在地
              </th>
              <th class="grey lighten-3">
                電話番号
              </th>
              <th class="grey lighten-3">
                プラント<br>
                認証番号
              </th>
              <th class="grey lighten-3 t-r">
                敷地面積 <div class="text-caption">(m2)</div>
              </th>
              <th class="grey lighten-3">
                処理能力 <div class="text-caption">(m3/時)</div>  
              </th>
              <th class="grey lighten-3">
                保有重機・台数
              </th>
              <th class="grey lighten-3">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in plantList"
              :key="item.name"
            >
              <td>{{ item.member_name }}</td>
              <td>{{ item.plant_name }}</td>
              <td>{{ item.address3 }}</td>
              <td>{{ item.tel }}</td>
              <td>{{ item.actec_no_pre }} {{item.actec_no}}</td>
              <td class="t-r"><div v-if="item.site_area>0">{{ numberFormat( item.site_area) }}</div></td>
              <td class="t-r"><div v-if="item.impsoil_ability_h>0">{{ numberFormat( item.impsoil_ability_h) }}</div></td>
              <td>{{ item.jyuuki_daisuu }}</td>
              <td>
                <v-btn
                  color="primary"
                  @click="clickDetail(item.plant_id)"
                >詳細</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      </v-card>
    </div>
  <dlg-plant-detail ref="refDlgPlant"></dlg-plant-detail>
  </div>
</template>
<style lang="scss" scoped>
.t-r{
  text-align: right !important;
}
.t-c{
  text-align: center !important;
}
.planttable table{
  table-layout: fixed;
}
.planttable table th{
  padding:5px !important;
  text-align: center !important;;
}
.planttable table tbody td {
  border: solid 1px #ddd !important;
  border-collapse: collapse;
  padding:5px !important;
}

.planttable table tbody td {
    border: solid 1px #ddd !important;
    border-collapse: collapse;
}
.planttable table tbody thead{
  position: sticky !important;
  top: 0;
  z-index: 2;
}
.v-data-table--fixed-header .v-data-table__wrapper table thead tr th{
  position: unset;
  border-top: solid 1px #ccc9c9 !important;
  border-right: solid 1px #ccc9c9 !important;
}
.v-data-table--fixed-header .v-data-table__wrapper table thead tr th:first-child{
  border-left: solid 1px #ccc9c9 !important;
  border-right: solid 1px #ccc9c9 !important;
}
</style>
<script>
  import DlgPlantDetail from '../components/dialog/DlgPlantDetail.vue';
  export default {
    name: 'Home',

    components: {
      DlgPlantDetail,
    },

    data () {
      return {
        //search condtion
        selShibu: {shibu_cd: ''},
        shibu:[],
        selTdfk: {tdfk_cd: ''},
        tdfk:[],
        dispTdfk:[],

        // list data
        plantHeaders: [
          { text: 'プラント名称', value: 'plant_name', class: 'plant-header' },
          { text: '所在地', value: 'address3' },
          { text: '電話番号', value: 'tel' },
          { text: "プラント認証番号", value: 'tel' },
          { text: '', value: 'detail' },
        ],
        plantList:[],
      }
    },
    mounted(){
      this.getMstData();
      this.getPlantList();
    },
    methods: {
      changeShibu() {
        if(this.selShibu.shibu_cd == ''){
          this.dispTdfk = this.tdfk;
          this.selTdfk = {tdfk_cd: ''};
        }else{
          this.dispTdfk = this.tdfk.filter( item => item.shibu_cd == this.selShibu.shibu_cd );
          this.dispTdfk.unshift({ tdfk_name: '全て', tdfk_cd: '' });
          this.selTdfk = { tdfk_name: '全て', tdfk_cd: '' };
        }
        this.getPlantList();
      },
      changeTdfk() {
        this.getPlantList();
      },
      async getPlantList() {
            const postData = {
                shibu_id: this.selShibu.shibu_cd,
                tdfk_cd: this.selTdfk.tdfk_cd,
            }
            await this.axios.post('/plant-search', postData)
            .then((res) => {
                this.plantList = res.data.resultData.plants;
            })
      },
      getMstData() {
         this.axios.get('general/get-mstdata')
            .then((res) => {
                this.shibu = res.data.resultData.shibu;
                this.shibu.unshift({ shibu_name: '全て', shibu_cd: '' });

                this.tdfk = res.data.resultData.tdfk;
                this.tdfk.unshift({ tdfk_name: '全て', tdfk_cd: '', shibu_cd: ''});
                this.dispTdfk = this.tdfk;
            });
      },
      clickDetail(plantId){
        console.log(this.$refs)
        this.$refs.refDlgPlant.open(plantId);
      }
    },
  }
</script>
