<template>
    <v-container fluid>
        <!-- menu -->
        <v-card class="menu mx-auto">
            <v-navigation-drawer
                app
                v-model="isMenu"
                clipped
                color="blue lighten-5"
            >
                <v-container class="">
                    <div class="mt-3">
                        <span>住所</span>
                        <v-divider class="mb-3"></v-divider>
                        <v-text-field
                            v-model="address"
                            outlined
                            dense
                            hide-details=""
                            persistent-hint
                            style="width: 300px"
                            @keydown.enter="changeAddress"
                        ></v-text-field>
                    </div>
                    <div class="mt-3">
                        <span>支部</span>
                        <v-divider class="mb-3"></v-divider>
                        <v-select
                            v-model="selShibu"
                            :items="shibu"
                            item-text="shibu_name"
                            item-value="shibu_cd"
                            outlined
                            dense
                            hide-details=""
                            persistent-hint
                            return-object
                            @change="changeShibu"
                        ></v-select>
                    </div>
                    <div class="mt-3">
                        <span>都道府県</span>
                        <v-divider class="mb-3"></v-divider>
                        <v-select
                            v-model="selTdfk"
                            :items="dispTdfk"
                            item-text="tdfk_name"
                            item-value="tdfk_cd"
                            outlined
                            dense
                            hide-details=""
                            persistent-hint
                            style="width: 200px"
                            @change="selectTdfk"
                            return-object
                        ></v-select>
                    </div>
                    <div class="mt-5">
                        <span>受入土質</span>
                        <v-divider></v-divider>
                        <v-checkbox
                            v-model="doshitsuList"
                            v-for="(item, index) in doshitsu"
                            :key="index"
                            :label="item.kubun_value1"
                            :value="item.kubun_cd"
                            dense
                            outlined
                            hide-details=""
                            persistent-hint
                        />
                    </div>
                    <div class="mt-5">
                        <span>改良土の種類</span>
                        <v-divider></v-divider>
                        <v-checkbox
                            v-model="kairyoList"
                            v-for="(item, index) in kairyo"
                            :key="index"
                            :label="item.kubun_value1"
                            :value="item.kubun_cd"
                            dense
                            outlined
                            hide-details=""
                            persistent-hint
                        />
                    </div>
                    <div class="mt-3">
                        <span>受入可能量</span>
                        <v-divider></v-divider>
                        <div class="quantity-area">
                            <v-text-field
                                class="my-2 quantity-box"
                                type="text"
                                maxlength="10"
                                oninput="value = value.replace(/[^0-9]+/i,'')"
                                dense
                                outlined
                                hide-details=""
                                persistent-hint
                                v-model="ukeire_ryo"
                            ></v-text-field>
                            <span class="mb-2 ml-1">m3以上</span>
                        </div>
                    </div>
                    <div class="mt-3">
                        <span>ストック量</span>
                        <v-divider></v-divider>
                        <div class="quantity-area">
                            <v-text-field
                                class="my-2 quantity-box"
                                type="text"
                                maxlength="10"
                                oninput="value = value.replace(/[^0-9]+/i,'')"
                                dense
                                outlined
                                hide-details=""
                                persistent-hint
                                v-model="stock_ryo"
                            ></v-text-field>
                            <span class="mb-2 ml-1">m3以上</span>
                        </div>
                    </div>
                    <div class="search-btn mt-10">
                        <v-btn
                            @click="searchPlant"
                            color="blue"
                            class="white--text"
                            >絞り込み</v-btn
                        >
                    </div>
                </v-container>
            </v-navigation-drawer>
        </v-card>

        <!-- main -->

        <div class="map-area" id="map"></div>
        <div class="plant-list-area" v-if="!$vuetify.breakpoint.mobile">
            <div class="plant-thead">
                <table class="plant-list-table">
                    <col style="width: 25%" />
                    <col style="width: 20%" />
                    <col style="width: 30%" />
                    <col style="width: 15%" />
                    <col style="width: auto" />
                    <thead>
                        <tr>
                            <th>会員名</th>
                            <th>プラント名</th>
                            <th>住所</th>
                            <th class="text-right">受入可能量</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="plant-tbody">
                <table class="plant-list-table">
                    <col style="width: 25%" />
                    <col style="width: 20%" />
                    <col style="width: 30%" />
                    <col style="width: 15%" />
                    <col style="width: auto" />
                    <tbody>
                        <template v-for="(item, index) in plantDispList">
                            <tr
                                :class="[
                                    item.plant_id == selPlant
                                        ? 'select-row'
                                        : '',
                                ]"
                            >
                                <td :id="'row-' + item.plant_id">
                                    {{ item.member_name }}
                                </td>
                                <td>{{ item.plant_name }}</td>
                                <td>{{ item.address3 }}</td>
                                <td class="text-right">
                                    <span v-if="item.now_gendo_uke != 0"
                                        >{{ item.now_gendo_uke }}&nbsp;m3</span
                                    >
                                    <span v-else class="red--text">不可</span>
                                </td>
                                <td class="text-center">
                                    <v-btn
                                        small
                                        class="ml-1 mr-1 my-1 btn-edit"
                                        @click="clickPlantDetail(item.plant_id)"
                                        >詳細</v-btn
                                    >
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <dlg-plant-detail
            ref="refDlgPlantDetail"
            :call-dlg-contact="callDlgContact"
        ></dlg-plant-detail>
        <dlg-plant-contact ref="refDlgPlantContact"></dlg-plant-contact>
    </v-container>
</template>
<style lang="scss" scoped>
.map-area {
    width: 100%;
    height: 500px;
    z-index: 0;
}
.search-area {
    width: 100%;
}
.plant-list-area {
    width: 100%;
    margin-top: 20px;
}
.quantity-box {
    width: 120px;
}
.quantity-area {
    display: flex;
    align-items: flex-end;
}
.search-btn {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}
.detail-table th {
    width: 100px;
    height: 30px;
    z-index: 3;
}
.select-row {
    background-color: #eafdff;
}
</style>
<script>
import { MESSAGE } from "../messages";
import moment from "moment";
import DlgPlantDetail from "../components/dialog/DlgPlantDetail.vue";
import DlgPlantContact from "../components/dialog/DlgPlantContact.vue";
import "leaflet/dist/leaflet.css";
import L, { Draggable } from "leaflet";
require("leaflet.markercluster");
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import sprite from "../assets/leaflet.sprite";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
    name: "MapList",
    components: { DlgPlantDetail, DlgPlantContact },
    data() {
        return {
            expand: true,
            url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
            map: {},
            tileLayer: {},
            groupLayer: {},
            markerList: {},
            lat: 38.263033,
            lng: 140.871437,
            zoom: 13,

            mapIconGreen: {},
            mapIconRed: {},
            mapIconBlue: {},
            selPlant: "",
            plantDispList: [],
            plantList: [],

            shibu: [],
            tdfk: [],
            dispTdfk: [],
            doshitsu: [],
            kairyo: [],

            // 絞り込み条件
            address: "",
            selShibu: {},
            selTdfk: {},
            doshitsuList: ["01", "02", "03", "04"],
            kairyoList: ["01", "02", "03"],
            ukeire_ryo: "",
            stock_ryo: "",
        };
    },
    created() {
        window.markerClick = this.markerClick;
    },
    async mounted() {
        if (!this.$vuetify.breakpoint.mobile) {
            this.$nextTick(() => {
                this.isMenu = true;
            });
        }

        // プラント一覧取得
        await this.getPlantList();

        // マスタデータ一覧取得
        await this.getMstData();

        this.$nextTick(() => {
            this.tileLayer = new L.tileLayer(this.url, { opacity: 0.7 });
            this.map = new L.map("map");
            this.map.addLayer(this.tileLayer);
            this.map.setView([this.lat, this.lng], this.zoom);
            this.addMarker();
            this.map.on("moveend", this.mapMove);
            this.map.on("zoomend", this.mapMove);
        }, this);

        this.getPosition();
    },
    computed: {
        isMenu: {
            get() {
                return this.$store.state.showMenu;
            },
            set(value) {
                this.$store.commit("setShowMenu", { flg: value });
            },
        },
    },
    methods: {
        /**
         * マップの移動(ズーム)イベント
         */
        mapMove() {
            const bns = this.map.getBounds();
            const southWest = bns.getSouthWest();
            const northEast = bns.getNorthEast();
            this.plantDispList = this.plantList.filter(function (val) {
                return (
                    southWest.lat < val.lat &&
                    val.lat < northEast.lat &&
                    southWest.lng < val.lng &&
                    val.lng < northEast.lng
                );
            }, this);
        },
        getPosition: async function () {
            await navigator.geolocation.getCurrentPosition(
                this.get_coord,
                this.err
            );
        },
        get_coord: function (position) {
            this.lat = position.coords.latitude;
            this.lng = position.coords.longitude;

            this.map.setView([this.lat, this.lng], this.zoom);
        },
        err: async function () {
			if(this.$store.state.showErrorMap){
				return;
			}
			this.$store.commit('setShoErrorMap',true);
            // 確認メッセージ
            let res = await this.swalAlert(
                MESSAGE.TITLE_CONFIRM,
                "ブラウザの位置情報の取得が有効になっていません。\n位置情報の取得を有効にしてください。",
                "warning"
            );
            if (!res) {
                return;
            }
        },
        /**
         *  マーカーの追加
         */
        addMarker() {
            // this.groupLayer=new L.featureGroup();
            this.groupLayer = new L.markerClusterGroup({
                iconCreateFunction: function (cluster) {
                    var markers = cluster.getAllChildMarkers();
                    var flg = false;
                    for (var i = 0; i < markers.length; i++) {
                        if (markers[i].options.ukeire != "0") {
                            flg = true;
                        }
                    }
                    var cls = flg ? "blue" : "red";
                    return new L.DivIcon({
                        html: "<div><span>" + markers.length + "</span></div>",
                        className: "marker-cluster marker-cluster-" + cls,
                        iconSize: new L.Point(40, 40),
                    });
                },
            });
            for (var key in this.plantList) {
                if (!this.plantList[key].lat || !this.plantList[key].lng) {
                    // 緯度経度が存在しない場合は次へ
                    continue;
                }
                const mColor =
                    this.plantList[key].now_gendo_uke == 0 ? "red" : "";
                const mk = L.marker(
                    [this.plantList[key].lat, this.plantList[key].lng],
                    {
                        // icon:
                        //     this.rentList[key].ukeire == 1
                        //         ? this.mapIconRed
                        //         : this.mapIconBlue,
                        icon: L.spriteIcon(mColor),
                        plantId: this.plantList[key].plant_id,
                        ukeire: this.plantList[key].now_gendo_uke,
                    }
                );

                //マップ内に表示するプラント名
                const id = this.plantList[key].plant_id;
                const plantName = this.plantList[key].plant_name;
                const plantInf = this.plantList[key];

                mk.bindPopup(
                    `<a onclick="markerClick(${id})">${plantName}</a>`
                );
                document.addEventListener(`marker-${id}`, () => {
                    this.clickPlantDetail(plantInf.plant_id);
                });
                mk.on("click", this.clickMarker);
                this.markerList[this.plantList[key].plant_id] = mk;
                this.groupLayer.addLayer(
                    this.markerList[this.plantList[key].plant_id]
                );
            }

            this.groupLayer.addTo(this.map);

            //     if(this.groupLayer.getBounds()._northEast){
            //         // マーカーを全て表示するZoomにする
            //         this.map.fitBounds(this.groupLayer.getBounds());
            //     }
        },
        clickMarker(e) {
            this.selPlant = e.target.options.plantId;
            window.location.href = "#row-" + e.target.options.plantId;
        },
        async getPlantList() {
            const postData = {
                shibu_id: this.selShibu.shibu_cd,
                tdfk_cd: this.selTdfk.tdfk_cd,
                ukeire_do: this.doshitsuList,
                kairyo_zai: this.kairyoList,
                ukeire_ryo_from: Number(this.ukeire_ryo),
                stock_ryo_from: Number(this.stock_ryo),
            };
            await this.axios.post(`/plant-search`, postData).then((res) => {
                this.plantList = res.data.resultData.plants;
            });
        },
        async searchPlant() {
            // marker delete
            this.map.removeLayer(this.groupLayer);

            await this.getPlantList();

            // marker add
            this.addMarker();
            this.mapMove();
        },
        getMstData() {
            this.axios.get(`/general/get-mstdata`).then((res) => {
                this.shibu = res.data.resultData.shibu;
                this.shibu.unshift({
                    shibu_name: "全て",
                    shibu_cd: "",
                    lat: 36,
                    lng: 140,
                    scale: 5,
                });

                this.tdfk = res.data.resultData.tdfk;
                this.tdfk.unshift({
                    tdfk_name: "全て",
                    tdfk_cd: "",
                    lat: 36,
                    lng: 140,
                    scale: 5,
                });
                this.dispTdfk = this.tdfk;

                this.doshitsu = res.data.resultData.ukeire_do;
                for (let row in this.doshitsu) {
                    this.doshitsuList.push(this.doshitsu[row].kubun_cd);
                }

                this.kairyo = res.data.resultData.kairyo_zai;
                for (let row in this.kairyo) {
                    this.kairyoList.push(this.kairyo[row].kubun_cd);
                }
            });
        },
        clickPlantDetail(plantInf) {
            this.$refs.refDlgPlantDetail.open(plantInf);
        },
        changeShibu() {
            this.lat = this.selShibu.lat;
            this.lng = this.selShibu.lng;
            this.zoom = this.selShibu.scale;
            this.selTdfk = [];

            this.map.setView([this.lat, this.lng], this.zoom);
            this.mapMove();
            if (this.selShibu.shibu_cd == "") {
                this.dispTdfk = this.tdfk;
            } else {
                this.dispTdfk = this.tdfk.filter(
                    (item) => item.shibu_cd == this.selShibu.shibu_cd
                );
            }
        },
        selectTdfk() {
            this.lat = this.selTdfk.lat;
            this.lng = this.selTdfk.lng;
            this.zoom = this.selTdfk.scale;

            this.map.setView([this.lat, this.lng], this.zoom);
            this.mapMove();
        },
        changeAddress() {
            this.axios
                .post(`/address-latlng`, { address: this.address })
                .then((res) => {
                    if (res.data.resultCode == 0) {
                        this.lat = res.data.resultData.latlng.lat;
                        this.lng = res.data.resultData.latlng.lng;
                        this.zoom = 12;
                        this.map.setView([this.lat, this.lng], this.zoom);
                        this.mapMove();
                    }
                });
        },
        markerClick(id) {
            const e = new Event(`marker-${id}`);
            document.dispatchEvent(e);
        },
        callDlgContact() {
            this.$refs.refDlgPlantContact.open();
        },
    },
};
</script>