<template>
    <v-footer class="footer-area" v-if="isLogin" app>
        <p id="copyright">&copy; {{new Date().getFullYear()}} 一般社団法人全国建設発生土リサイクル協会</p>
    </v-footer>
</template>
<style lang="scss" scoped>
.footer-area{
    background: #202020 !important;
    color: #fff !important;
    font-size: 12px;
    padding: 10px 0;
}
.footer-area #copyright{
    margin: 0 auto;
}
</style>
<script>

export default {
    computed:{
        isLogin(){
            return true;
            // return this.$store.state.token==''?false:true;
        }
    },
    methods:{
    }
    
}
</script>
