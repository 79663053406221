<template>
    <v-data-table
    :headers="headers"
    :items="myitems"
    hide-default-header
    >

    <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th v-for="(h,index) in headers" class="column sortable desc active" :class="h.class" :key="index">
              <span>{{h.text}}</span>
            </th>
          </tr>
        </thead>
    </template>
    </v-data-table>
</template>

<script>
export default {
  name: 'MyComponent',

   data () {
        return {
            headers:[
                { text: '1 Header', value: 'first', class: '' },
                { text: '2 Header', value: 'thing', class: '' },
                { text: '3 Header', value: 'thing', class: '' },
            ],
            myitems : []
        }
    }
}
</script>

<style scoped>
.some-other-style {
  background: blue;
}
.my-header-style {
  color: #6f8fb9 !important;
}
</style>