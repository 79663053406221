import Vue from 'vue';
import axios from 'axios';

import store from '../store'
import router from '../router' 
const config = {
  // baseURL: 'http://192.168.24.249:1323/',
  //baseURL: 'https://test-api.sodai.site/',
  baseURL : 'https://test-api.tsuti.net/api/',
  timeout: 60 * 1000,
  headers: {
    //'Content-Type': 'application/json; charset=utf-8',
    //'X-Requested-With': 'XMLHttpRequest',
  }
};

if (document.domain == 'ds-web.ryukyu.co.jp'){
  config.baseURL = 'https://ds-api.ryukyu.co.jp/api/';
}
else if (document.domain == 'general.tsuti.net'){
  config.baseURL = 'https://api.tsuti.net/api/';
}
else if (document.domain == 'test-web.tsuti.net'){
  config.baseURL = 'https://test-api.tsuti.net/api/';
}

const _axios = axios.create(config);

//リクエスト時の共通化
_axios.interceptors.request.use(
  config => {
   config.headers.common['Authorization'] = 'Bearer ' + store.state.token;
    return config;
  }
)
//レスポンスの共通化
_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
        /**
         * トークンが期限切れの場合 401が発生する
         * エラー画面にリダイレクト
         */
        store.commit('setToken' ,'');
	if(router.currentRoute.name != "Login"){
		router.push({name:"Login"});
	}
    }
    return Promise.reject(error);
  }
)

Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios
      }
    },
    $axios: {
      get() {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin