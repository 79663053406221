<template>
	<v-dialog v-model="dialog" v-if="dialog" scrollable max-width="700px">
		<v-card class="detail-area">
			<v-card-title class="py-1 px-3" style="background: #1976d2; color: white;" height="40">
				プラント情報
			</v-card-title>
			<v-divider></v-divider>
			<!-- mobile -->
			<v-card-text v-if="$vuetify.breakpoint.mobile">
			<div class="basic-area mt-3">
				<div class="font-weight-black">基本情報</div>
				<v-list
					dense
				>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>会社名</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.member_name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>プラント名称</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.plant_name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>所在地・連絡先</v-list-item-subtitle>
							<v-list-item-title class="ml-2">
								<div>〒{{ plantDetail.post }}</div>
								<div>{{ plantDetail.address3 }}</div>
								<div v-if="plantDetail.tantou_name">担当：{{ plantDetail.tantou_name }}</div>
								<div v-if="plantDetail.tel">（電話）{{ plantDetail.tel }}</div>
								<div v-if="plantDetail.fax">（FAX）{{ plantDetail.fax }}</div>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>ホームページ</v-list-item-subtitle>
							<v-list-item-title class="ml-2">
								<span class="text-left" v-if="plantDetail.homepage">
								<a :href="plantDetail.homepage" target="_blank">{{plantDetail.homepage}}</a>
								</span>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>プラント認証番号</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.actec_no }}&nbsp;{{ plantDetail.actec_no_pre }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>営業日</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.sales_day }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>営業時間</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.sales_time }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>稼働年月</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.kado_ym }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>敷地面積</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ numberFormat(plantDetail.site_area) }}&nbsp;m2</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>原料土ヤード / 最大ストック量</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ numberFormat(plantDetail.mate_area) }}&nbsp;m2 / {{ numberFormat(plantDetail.mate_stock) }}&nbsp;m3</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>改良土ヤード / 最大ストック量</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ numberFormat(plantDetail.impsoil_area) }}&nbsp;m2 / {{ numberFormat(plantDetail.impsoil_stock) }}&nbsp;m3</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>土質改良能力</v-list-item-subtitle>
							<v-list-item-title class="ml-2">
								<span>{{ plantDetail.impsoil_ability_h }}&nbsp;m3/時&nbsp;</span>
								<span>{{ plantDetail.impsoil_ability_d }}&nbsp;m3/日&nbsp;</span>
								<span>{{ plantDetail.impsoil_ability_y }}&nbsp;m3/年&nbsp;</span>
						</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>改良土の種類</v-list-item-subtitle>
							<v-list-item-title class="ml-2">
								<span v-for="(item, index) in kairyo" :key="index">
									<span v-if="kairyoZai.indexOf(item.kubun_cd) !== -1">{{item.kubun_value1}}&nbsp;&nbsp;</span>
								</span>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>受入土質</v-list-item-subtitle>
							<v-list-item-title class="ml-2">
								<span v-for="(item, index) in ukeireDoList" :key="index">
									<span v-if="ukeireDo.indexOf(item.kubun_cd) !== -1">{{item.kubun_value1}}&nbsp;&nbsp;</span>
								</span>
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>その他受入条件</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.gendo_uke_jyoken }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</div>
			<div class="px-3 mt-3">
				<div class="font-weight-black">原料土情報</div>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>土質</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.hp_gendo_uke_name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>料金</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.hp_gendo_uke_kingaku }}円 /地山 m3</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>受入可能量</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.now_gendo_uke }}&nbsp;m3</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>受入可否</v-list-item-subtitle>
							<v-list-item-title class="ml-2"><span :class="[plantDetail.now_gendo_uke == 0?'red-font':'']">{{ plantDetail.now_gendo_uke == 0 ? '不可' : '可' }}</span></v-list-item-title>
						</v-list-item-content>
					</v-list-item>
			</div>
			<div class="px-3 mt-3">
				<div class="font-weight-black">改良土情報</div>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>土質</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.hp_kaido_name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>料金</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.hp_kaido_kingaku }}円  /ほぐし m3</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-subtitle>ストック量</v-list-item-subtitle>
							<v-list-item-title class="ml-2">{{ plantDetail.now_kaido_stock }}&nbsp;m3</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
			</div>
			<div class="px-3 mt-3">
				<div class="font-weight-black">料金表</div>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-title>
							<div class="ryokin-link" v-show="disp_flg">
								<v-btn 
								text
								@click="clickDownload(pdf_file, '料金表')"
								class="post-link">料金表はこちら</v-btn>
							</div>
							<div v-show="!disp_flg">
								料金表なし
							</div>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</div>
			<div class="px-3 mt-3">
				<div class="font-weight-black">申込み</div>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-title v-if="plantDetail.moshikomi_kbn == 1">
							<a :href="plantDetail.moshikomi_url" target="_blank" rel="noopener noreferrer">{{ plantDetail.moshikomi_url }}</a>
						</v-list-item-title>
						<v-list-item-title v-else-if="plantDetail.moshikomi_kbn == 2">
							<div class="ryokin-link" v-show="moshikomi_flg">
								<v-btn 
								text
								@click="clickDownload(moshikomi_file, '申込み')"
								class="post-link">申込み用紙はこちら</v-btn>
							</div>
							<div v-show="!moshikomi_flg">
								申込み用紙なし
							</div>
						</v-list-item-title>
						<v-list-item-title v-else>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</div>
			<div class="px-3 mt-3">
				<div class="font-weight-black">改良土品質管理基準（試験頻度）</div>
				<div class="font-weight-black ml-1" style="font-size: 14px;">力学的指数</div>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>CBR</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_cbr }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>一軸圧縮強度</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_itijiku }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>コーン指数</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_cone }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<div class="font-weight-black ml-1" style="font-size: 14px;">粒径</div>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>最大粒径</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_ryukei }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>細粒分</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_sairyu }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<div class="font-weight-black ml-1 mt-5" style="font-size: 14px;"></div>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>含水比</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_gansui }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>pH</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_ph }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>土壌環境基準</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_dojyou }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item dense>
					<v-list-item-content dense class="mt-0 pt-0">
						<v-list-item-subtitle>その他</v-list-item-subtitle>
						<v-list-item-title class="ml-2">{{ plantDetail.manage_etc }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</div>
			<div class="px-3 mt-3">
				<div class="font-weight-black">保有重機と台数</div>
					<v-list-item dense>
						<v-list-item-content dense class="mt-0 pt-0">
							<v-list-item-title class="ml-2">{{ plantDetail.jyuuki_daisuu }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
			</div>
			</v-card-text>

			<!-- pc -->
			<v-card-text v-else>
				<div class="basic-area px-5 mt-5">
					<div class="text-h6 indigo--text font-weight-bold">基本情報</div>
					<v-divider class="mb-3" ></v-divider>
					<table class="plant-list-table">
						<col style="width: 28%" />
						<col style="width: 22%" />
						<col style="width: 28%" />
						<col style="width: 22%" />
						<tr>
							<th class="text-left">会社名称</th>
							<td class="pl-1" colspan="3">{{ plantDetail.member_name }}</td>
						</tr>
						<tr>
							<th class="text-left">プラント名称</th>
							<td class="pl-1" colspan="3">{{ plantDetail.plant_name }}</td>
						</tr>
						<tr>
							<th class="text-left">所在地・連絡先</th>
							<td class="pl-1" colspan="3">
								<div>〒{{ plantDetail.post }}</div>
								<div>{{ plantDetail.address3 }}</div>
								<div v-if="plantDetail.tantou_name"><span class="text-caption">担当：</span>{{ plantDetail.tantou_name }}</div>
								<div><span v-if="plantDetail.tel"><span class="text-caption">（電話）</span>{{ plantDetail.tel }}</span><span v-if="plantDetail.fax"><span class="text-caption">（FAX）</span>{{ plantDetail.fax }}</span></div>
							</td>
						</tr>
						<tr>
							<th class="text-left">ホームページ</th>
							<td class="pl-1" colspan="3">
								<template v-if="plantDetail.homepage">
									<a :href="plantDetail.homepage" target="_blank">{{plantDetail.homepage}}</a>
								</template>
								<template v-else>
									<a :href="plantDetail.home_page" target="_blank">{{plantDetail.home_page}}</a>
								</template>
							</td>
						</tr>
						<tr>
							<th class="text-left">プラント認証番号</th>
							<td class="pl-1" colspan="3">(ACTEC){{ plantDetail.actec_no }}&nbsp;(自治体){{ plantDetail.actec_no_pre }}</td>
						</tr>
						<tr>
							<th class="text-left">営業日</th>
							<td class="pl-1" colspan="3">{{ plantDetail.sales_day }}</td>
						</tr>
						<tr>
							<th class="text-left">営業時間</th>
							<td class="pl-1" colspan="3">{{ plantDetail.sales_time }}</td>
						</tr>
						<tr>
							<th class="text-left">稼働年月</th>
							<td class="t-r">{{ plantDetail.kado_ym }}</td>
							<th class="text-left">敷地面積</th>
							<td class="t-r">
								<div v-if="plantDetail.site_area>0">
								<span>{{ numberFormat(plantDetail.site_area) }}</span>
								<span class="text-caption ml-1">m2</span>
								</div>
							</td>
						</tr>
						<tr>
							<th class="text-left">原料土ヤード</th>
							<td class="t-r">
								<div v-if="plantDetail.mate_area>0">
								<span>{{ numberFormat(plantDetail.mate_area) }}</span>
								<span class="text-caption ml-1">m2</span>
								</div>
							</td>
							<th class="text-left">最大ストック量</th>
							<td class="t-r">
								<div v-if="plantDetail.mate_stock>0">
								<span>{{ numberFormat(plantDetail.mate_stock) }}</span>
								<span class="text-caption ml-1">m3</span>
								</div>
							</td>
						</tr>
						<tr>
							<th class="text-left">改良土ヤード</th>
							<td class="t-r">
								<div v-if="plantDetail.impsoil_area>0">
									<span>{{ numberFormat(plantDetail.impsoil_area) }}</span>
									<span class="text-caption ml-1">m2</span>
								</div>
							</td>
							<th class="text-left">最大ストック量</th>
							<td class="t-r">
								<div v-if="plantDetail.impsoil_stock>0">
								<span>{{ numberFormat(plantDetail.impsoil_stock) }}</span>
								<span class="text-caption ml-1">m3</span>
								</div>
								</td>
						</tr>
						<tr>
							<th class="text-left">土質改良能力</th>
							<td class="pl-1" colspan="3">
								<span class="mr-3" v-if="plantDetail.impsoil_ability_h>0">{{ plantDetail.impsoil_ability_h }}<span class="text-caption ml-1">m3/時</span></span>
								<span class="mr-3" v-if="plantDetail.impsoil_ability_d>0">{{ plantDetail.impsoil_ability_d }}<span class="text-caption ml-1">m3/日</span></span>
								<span class="mr-3" v-if="plantDetail.impsoil_ability_y>0">{{ plantDetail.impsoil_ability_y }}<span class="text-caption ml-1">万m3/年</span></span>
							</td>
						</tr>
						<tr>
							<th class="text-left">改良土の種類</th>
							<td class="pl-1" colspan="3">
								<div class="d-inline-flex align-center">
									<v-checkbox v-for="(item, index) in kairyo" :key="index"
										v-model="kairyoZai"
										class="mr-6"
										:label="item.kubun_value1"
										:value="item.kubun_cd"
										readonly
										dense
										outlined
										hide-details=""
										persistent-hint
									/>
								</div>
							</td>
						</tr>
						<tr>
							<th class="text-left">受入土質</th>
							<td class="pl-1" colspan="3">
								<div class="d-inline-flex">
									<v-checkbox v-for="(item, i) in ukeireDoList" :key="i"
											v-model="ukeireDo"
											class="mr-2"
											:label="item.kubun_value1"
											:value="item.kubun_cd"
											dense
											readonly
											hide-details=""
									/>
							</div>
							</td>
						</tr>
						<tr>
							<th class="text-left">その他受入条件</th>
							<td class="pl-1" colspan="3">{{ plantDetail.gendo_uke_jyoken }}</td>
						</tr>
					</table>
				</div>
				<div class="px-5 mt-10">
					<div class="text-h6 indigo--text font-weight-bold">原料土／改良土</div>
					<v-divider class="mb-3"></v-divider>
					<table class="plant-list-table line">
					<col style="width: 100px" />
					<col style="width: auto" />
					<col style="width: 120px" />
					<col style="width: 120px" />
					<col style="width: 70px" />
					<tr>
						<th class="py-1"></th>
						<th class="py-1">土質</th>
						<th class="py-1">料金</th>
						<th class="py-1">受入可能量<br/>ストック量</th>
						<th class="py-1">受入<br/>可否</th>
					</tr>
					<tr>
						<th>原料土</th>
						<td>{{ plantDetail.hp_gendo_uke_name }}</td>
						<td class="t-r">
							<div v-if="plantDetail.hp_gendo_uke_kingaku">
							<div><span>{{ numberFormat(plantDetail.hp_gendo_uke_kingaku) }}</span><span class="text-caption ml-1">円</span></div>
							<div class="text-caption">(地山 m3)</div>
							</div>
						</td>
						<td class="t-r">
							<span class="mr-1">{{ numberFormat(plantDetail.now_gendo_uke) }}</span>
							<span class="text-caption">m3</span></td>
						<td class="t-c"><span :class="[plantDetail.now_gendo_uke == 0?'red-font':'']">{{ plantDetail.now_gendo_uke == 0 ? '不可' : '可' }}</span></td>
					</tr>
					<tr>
						<th>改良土</th>
						<td>{{ plantDetail.hp_kaido_name }}</td>
						<td class="t-r">
							<div v-if="plantDetail.hp_kaido_kingaku">
							<div><span>{{ numberFormat( plantDetail.hp_kaido_kingaku) }}</span><span class="text-caption ml-1">円</span></div>
							<div class="text-caption">(ほぐし m3)</div>
							</div>
						</td>
						<td class="t-r">
							<span class="mr-1">{{ plantDetail.now_kaido_stock }}</span>
							<span class="text-caption">m3</span>
							</td>
						<td></td>
					</tr>
					<tr>
						<th>料金表</th>
						<td colspan="4">
							<div class="ryokin-link" v-show="disp_flg">
								<v-btn 
								text
								@click="clickDownload(pdf_file, '料金表')"
								class="post-link">料金表はこちら</v-btn>
							</div>
							<div v-show="!disp_flg">
								料金表なし
							</div>
						</td>
					</tr>
					<tr>
						<th>申込み</th>
						<td v-if="plantDetail.moshikomi_kbn == 1" colspan="4">
							<a :href="plantDetail.moshikomi_url" target="_blank" rel="noopener noreferrer">{{ plantDetail.moshikomi_url }}</a>
						</td>
						<td v-else-if="plantDetail.moshikomi_kbn == 2" colspan="4">
							<div class="ryokin-link" v-show="moshikomi_flg">
								<v-btn 
								text
								@click="clickDownload(moshikomi_file, '申込み')"
								class="post-link">申込み用紙はこちら</v-btn>
							</div>
							<div v-show="!moshikomi_flg">
								申込み用紙なし
							</div>
						</td>
						<td v-else colspan="4">
						</td>
					</tr>
					</table>
				</div>
				<div class="px-5 mt-10">
					<div class="text-h6 indigo--text font-weight-bold">改良土品質管理基準（試験頻度）</div>
					<v-divider class="mb-3"></v-divider>
					<table class="plant-list-table line">
					<col style="width: 20%" />
					<col style="width: 20%" />
					<col style="width: 20%" />
					<col style="width: 20%" />
					<col style="width: 20%" />
					<tr>
						<th rowspan="3">力学的指数</th>
						<td class="cell-title">CBR</td>
						<td>{{ plantDetail.manage_cbr }}</td>
						<td class="cell-title">含水比</td>
						<td>{{ plantDetail.manage_gansui }}</td>
					</tr>
					<tr>
						<td class="cell-title">一軸圧縮強度</td>
						<td>{{ plantDetail.manage_itijiku }}</td>
						<td class="cell-title">pH</td>
						<td>{{ plantDetail.manage_ph }}</td>
					</tr>
					<tr>
						<td class="cell-title">コーン指数</td>
						<td>{{ plantDetail.manage_cone }}</td>
						<td class="cell-title">土壌環境基準</td>
						<td>{{ plantDetail.manage_dojyou }}</td>
					</tr>
					<tr>
						<th rowspan="2">粒径</th>
						<td class="cell-title">最大粒径</td>
						<td>{{ plantDetail.manage_ryukei }}</td>
						<td class="cell-title">その他</td>
						<td>{{ plantDetail.manage_etc }}</td>
					</tr>
					<tr>
						<td class="cell-title">細粒分</td>
						<td>{{ plantDetail.manage_sairyu }}</td>
						<td colspan="2"></td>
					</tr>
					</table>
				</div>
				<div class="px-10 mt-10">
					<div class="text-h6 indigo--text font-weight-bold">保有重機と台数</div>
					<v-divider class="mb-3"></v-divider>
					<table class="plant-list-table line">
						<tr>
							<td>&nbsp;{{ plantDetail.jyuuki_daisuu }}</td>
						</tr>
					</table>
				</div>
			</v-card-text>
			<v-divider></v-divider>
			<v-card-actions class="btns-area ma-2">
				<v-btn class="mr-2 px-4 btn-close" @click="dialog=false">閉じる</v-btn>
				<v-btn @click="openContact" class="btn-save px-4">お問合せ</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<style scoped>
	.t-r{
		text-align:right;
	}
	.t-c{
		text-align:center;
	}
	.plant-list-table th{
		text-align: center;
	}
	.plant-list-table td{
		padding:5px !important;
	}
	.detail-area .v-card__text{
		font-size: 16px !important;
		color: #212121 !important;
	}
	.detail-area .detail-title{
		font-size: medium;
		font-weight: 900 !important;
		color: #212121;
	}
    .btns-area {
	    display:flex;
	    justify-content: right;
	}
	.post-link {
		color:#1976d2 !important;
		display: inline-block;
		word-break: break-all;
		padding: 2px 8px !important;
		border-radius: 5px;
		text-transform: none !important;
		white-space: normal;
		max-width:500px; 
		height: inherit !important;
	}
</style>
<script>
import {mapState} from 'vuex'
// import RyokinFile from "../../components/RyokinFile.vue"
export default {
    // components: { RyokinFile },
	props: {
        callDlgContact: {
            type: Function,
        },
    },
    data: () => ({
        dialog: false,

		// プラント詳細
        dispDetail: true,
        plantDetail: {},
        disp_flg: false,
        ignore_flg: false,
        pdf_file: {},

		moshikomi_flg: false,
		moshikomi_file: {},

		// プラント改良情報(データ)
        ukeireDo: '',
        kairyoZai: '',
        jituseki: '',

		// プラント改良情報(項目リスト)
        kairyo: [],
        ukeireDoList: [],
    }),
    mounted(){
    },
    methods: {
        async open(plant_id) {
            this.dialog = true;
			this.getMstData();
			//this.plantDetail = plantInf;
			await this.getPlantInfo(plant_id);

			await this.ryokinFile();
			await this.moshikomiFile();
			
        },
		getMstData() {
			this.axios.get(`/general/get-mstdata`)
			.then((res) => {
				this.kairyo = res.data.resultData.kairyo_zai;
				this.ukeireDoList = res.data.resultData.ukeire_do;
			});
		},
		async getPlantInfo(plant_id){
			//Get Plant All Data
			const res = await this.axios.post(`/plant-get`,{plant_id:plant_id});
			if (res.data.resultCode == 0) {
					this.plantDetail = res.data.resultData.plantinfo;
					this.ukeireDo = res.data.resultData.ukeire_do;
					this.kairyoZai= res.data.resultData.kairyozai
					this.jituseki = res.data.resultData.jituseki;
			}
		},
		clickDownload(file, pdf_name){
				var blob = this.toBlob(file, 'application/pdf')

				var filename = pdf_name + '.pdf'; 
				var link = document.createElement('a');
				document.body.appendChild(link);
				link.href = URL.createObjectURL(blob);
				link.download = filename; 
				link.click();
				document.body.removeChild(link);
			},
			toBlob(data64, mime_ctype) {
				var bin = atob(data64.replace(/^.*,/, ''));
				var buffer = new Uint8Array(bin.length);
				for (var i = 0; i < bin.length; i++) {
					buffer[i] = bin.charCodeAt(i);
				}
				// Blobを作成
				try {
					var blob = new Blob([buffer.buffer], {
						type: mime_ctype,
					});
				} catch (e) {
					return false;
				}
				return blob;
			},
		async ryokinFile() {
			this.disp_flg = false;
			await this.axios.post(`/ryokin-file-download`,{plant_id: this.plantDetail.plant_id, download_flg:'1'})
			.then((res)=>{
				if(res.data.resultCode==0){
					this.pdf_file = res.data.resultData.file;
					if(res.data.resultData.pdf_flg==1){
						this.disp_flg = true;
					}else{
						if(this.ignore_flg=="true"){
							this.disp_flg = true;
						}else{
							this.disp_flg = false;
						}
					}
				}
			});
		},
		async moshikomiFile() {
			this.moshikomi_flg = false;
			await this.axios.post(`/moshikomi-file-download`,{plant_id: this.plantDetail.plant_id, download_flg:'1'})
			.then((res)=>{
				if(res.data.resultCode==0){
					this.moshikomi_file = res.data.resultData.file;
					this.moshikomi_flg = true;
				}
			});
		},
		openContact() {
			// 問い合わせを行うプラントIDをセット
			this.$store.commit("setContactPlantInfo", {
				plant_id: this.plantDetail.plant_id,
				member_name: this.plantDetail.member_name,
				plant_name: this.plantDetail.plant_name
			});
			this.dialog = false;
			this.callDlgContact();
		},
    },
};
</script>
