import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //メニューの表示・非表示を保存 true:表示
    showMenu:true,
    //マップ利用エラーの表示済みフラグ
    showErrorMap:false,
    //初期起動フラグ
    initFlag:false,
    //お問い合わせプラントID
    contactPlantInfo:[],
  },
  mutations: {
    chgShowMenu(state){
      state.showMenu = !state.showMenu;
    },
    setShowMenu(state, payload){
      state.showMenu = payload.flg;
    },
    setShoErrorMap(state,value){
      state.showErrorMap = value;
    },
    setInitFlag(state,value){
      state.initFlag = value;
    },
    setContactPlantInfo(state, value){
      state.contactPlantInfo = value;
    }

  },
  actions: {
  },
  modules: {
  }
})
